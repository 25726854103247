import React from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useCategoryList } from "../hooks/useCategoryList";

const FullHeightSlider = () => {
  const { t } = useTranslation();
  const categoryList = useCategoryList();
  const settings = {
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 800,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    swipe: true,
    swipeToSlide: true,
  };
  return (
    <MySlider {...settings}>
      {categoryList.map((props, i) => {
        const img = getImage(props.frontmatter.featuredImage.childImageSharp);
        const { id, title } = props.frontmatter;
        return (
          <div key={id} className="s-slide">
            <Link to={props.fields.slug}>
              <GatsbyImage
                loading="eager"
                image={img}
                alt={t(title)}
                className="gatsby-img-wrapper"
                imgClassName="gatsby-img"
              />
              <SlideContent>
                <h2>{t(title)}</h2>
                <p>
                  <Trans>Explore &#10687;</Trans>
                </p>
              </SlideContent>
            </Link>
          </div>
        );
      })}
    </MySlider>
  );
};

export default FullHeightSlider;

const MySlider = styled(Slider)`
  position: relative;
  height: 100vh;
  @media ${({ theme }) => theme.device.phone} {
    height: 92vh;
  }
  .slick-list {
    height: 100%;

    .slick-track {
      height: 100%;

      .slick-slide {
        div {
          height: 100%;
        }
        .s-slide {
          position: relative;
          display: block;
          height: 100%;

          &:hover .gatsby-img {
            transform: scale(1.03);
          }
          .gatsby-img {
            transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1);
          }
          .gatsby-img-wrapper {
            width: 100%;
          }
        }
      }
    }
  }
  .slick-dots {
    position: absolute;
    bottom: 0;
  }
  .slick-arrow {
    position: absolute;
    z-index: 1000;

    &:before {
      font-size: 5rem;
    }
  }
  .slick-prev {
    left: 5px;
  }
  .slick-next {
    right: 35px;
  }
`;

const SlideContent = styled.div`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.whites[0]};
  position: absolute;
  bottom: 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  /* background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.6) 170%
  ); */

  h2 {
    text-align: center;
    font-weight: 700;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 1);
  }

  padding-bottom: 20%;

  @media ${({ theme }) => theme.device.phone} {
    padding-bottom: 40%;
  }
`;
