import { useStaticQuery, graphql } from "gatsby";

export const useCategoryList = () => {
  const { categoryList } = useStaticQuery(
    graphql`
      query destinationList {
        categoryList: allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "destinations" } } }
          sort: { fields: frontmatter___sortId }
        ) {
          nodes {
            frontmatter {
              sortId
              title
              featuredImage {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            fields {
              slug
            }
          }
        }
      }
    `
  );
  return categoryList.nodes;
};
