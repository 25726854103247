import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import FullHeightSlider from "../components/full-height-slider";
import Header from "../components/header";
import Loader from "../components/loader";

const HomePage = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <main>
      {loading ? <Loader /> : null}
      <Header />
      <FullHeightSlider />
    </main>
  );
};

export default HomePage;

export const query = graphql`
  query HomePageQuery($id: String!, $language: String!) {
    markdown: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        sortId
        title
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
