import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import MobileMenu from "./mobile-menu";
import Container from "./container";
import IndibleuLogo from "../images/assets/indibleu-logo.svg";

const Header = ({ headerWithBg }) => {
  return (
    <Headers headerWithBg={headerWithBg}>
      <Container className="wrapper">
        <Link to="/">
          <Logo />
        </Link>
        <MobileMenu />
      </Container>
    </Headers>
  );
};

const Headers = styled("header")`
  position: absolute;
  background: transparent;
  width: 100%;
  z-index: 1000;

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  ${({ headerWithBg, theme }) =>
    headerWithBg && `position: relative; margin-bottom: 2rem;`};

  @media ${({ theme }) => theme.device.phone} {
    position: relative;
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

const Logo = styled(IndibleuLogo)`
  width: 18rem;
  display: block;
`;

export default Header;
