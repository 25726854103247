import React, { useState } from "react";
import styled from "styled-components";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import LanguageSelector from "./language-selector";
import { useCategoryList } from "../hooks/useCategoryList";

const MobileMenu = () => {
  const { t } = useTranslation();
  const categoryList = useCategoryList();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const closeMobileMenu = () => setIsMobileMenuOpen(false);
  return (
    <Menu>
      <div className={`menu ${isMobileMenuOpen ? "open" : ""}`}>
        <span className="menu__circle"></span>
        <div
          className="menu__link"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          <span className="menu__link-icon">
            <span className="menu__link-icon-line menu__link-icon-line--1"></span>
            <span className="menu__link-icon-line menu__link-icon-line--2"></span>
            <span className="menu__link-icon-line menu__link-icon-line--3"></span>
          </span>
        </div>
      </div>

      <nav className={`menu-overlay ${isMobileMenuOpen ? "open" : ""}`}>
        <LanguageSelector />
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          {categoryList.map((props) => {
            const { id, title } = props.frontmatter;
            return (
              <li>
                <Link to={props.fields.slug} key={id}>
                  {t(title)}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </Menu>
  );
};

const Menu = styled.div`
  --size: 6rem;
  .menu {
    position: relative;
    height: var(--size);
    width: var(--size);

    &:hover .menu__circle {
      transform: scale(1.2);
    }
    &.open .menu__circle {
      transform: scale(60);
      position: fixed;
    }
    &.open .menu__link-icon-line--1 {
      /* transform: translateY(7px) translateY(-50%) rotate(-135deg); */
      transform-origin: 0% 0%;
      transform: translateY(0%) rotate(45deg);
    }
    &.open .menu__link-icon-line--2 {
      opacity: 0;
    }
    &.open .menu__link-icon-line--3 {
      /* transform: translateY(-7px) translateY(50%) rotate(135deg); */
      transform-origin: 0% 100%;
      transform: translateY(100%) rotate(-45deg);
    }
    &__circle {
      background-color: ${({ theme }) => theme.colors.whites[0]};
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 50%;
      transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
      z-index: 1000;
      box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
    }
    &__link {
      width: 100%;
      height: 100%;
      position: absolute;
      cursor: pointer;
      z-index: 1002;
      &-icon {
        position: absolute;
        width: calc(var(--size) - 33%);
        height: calc(var(--size) - 55%);
        margin: auto;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        &-line {
          background-color: #333;
          height: 4px;
          width: 100%;
          border-radius: 2px;
          position: absolute;
          left: 0;
          transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          &--1 {
            top: 0;
          }
          &--2 {
            top: 0;
            bottom: 0;
            margin: auto;
          }
          &--3 {
            bottom: 0;
          }
        }
      }
    }
  }
  .menu-overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    background-color: ${({ theme }) => theme.colors.whites[0]};
    color: ${({ theme }) => theme.colors.blacks[3]};
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 1001;
    overflow: scroll;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out;

    &.open {
      opacity: 1;
      visibility: visible;
      transition-delay: 0.03s;
    }

    ul {
      list-style: none;
      li {
        a {
          text-decoration: none;
          color: inherit;
          font-size: 2.2rem;
          line-height: 2.2;
          font-weight: 600;
        }
      }
    }
  }
`;

export default MobileMenu;
